let Offcanvas;

if (typeof window !== "undefined") {
  Offcanvas = require('bootstrap/js/dist/offcanvas');
}

// Function to apply filters to a list of items
export const applyFilters = (items, filters) => {
  return items.filter((item) => {
    const statusMatch =
      filters.status.length === 0 ||
      (item.projectStatus &&
        filters.status.some((status) => item.projectStatus.includes(status)));

    const techMatch =
      filters.technologies.length === 0 ||
      (item.technologiesUsed &&
        filters.technologies.some((tech) => item.technologiesUsed.includes(tech)));

    return statusMatch && techMatch;
  });
};

// Function to paginate a list of items
export const paginateItems = (items, currentPage, itemsPerPage) => {
  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return items.slice(start, end);
};

// Function to get unique values from a specific key in a list of items
export const getUniqueValues = (items, key) => {
  return Array.from(
    new Set(items.flatMap((item) => item[key] || []))
  );
};

export const calculateDuration = (startDate, endDate = new Date()) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffInMonths =
    (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();
  const years = Math.floor(diffInMonths / 12);
  const months = diffInMonths % 12;

  const yearText = years ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthText = months ? `${months} month${months > 1 ? 's' : ''}` : '';
  return [yearText, monthText].filter(Boolean).join(' ');
};

// Function to adjust section offsets
export const adjustSectionOffset = () => {
  if (typeof document !== 'undefined') {
    const offset = document.getElementById('current-nav')?.offsetHeight || 0;
    document.querySelectorAll('section').forEach((section) => {
      section.style.scrollMarginTop = `${offset}px`;
    });
  }
};

// Function to handle section scrolling
export const handleSectionScroll = (direction, activeSection) => {
  if (typeof document !== 'undefined') {
    const sections = Array.from(document.querySelectorAll('section'));
    const currentIndex = sections.findIndex((section) => section.id === activeSection);

    if (direction === 'prev' && currentIndex > 0) {
      sections[currentIndex - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (direction === 'next' && currentIndex < sections.length - 1) {
      sections[currentIndex + 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};

// Function to adjust scroll offsets for sections
export const adjustScrollOffsets = (navId) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const offset = document.getElementById(navId)?.offsetHeight || 0;
    document.querySelectorAll('section').forEach((section) => {
      section.style.scrollMarginTop = `${offset}px`;
    });

    window.addEventListener('resize', () => {
      const offset = document.getElementById(navId)?.offsetHeight || 0;
      document.querySelectorAll('section').forEach((section) => {
        section.style.scrollMarginTop = `${offset}px`;
      });
    });
  }
};

// Function to handle section scrolling
export const scrollToSection = (direction, activeSection) => {
  if (typeof document !== 'undefined') {
    const sections = Array.from(document.querySelectorAll('section'));
    const currentIndex = sections.findIndex((section) => section.id === activeSection);

    if (direction === 'prev' && currentIndex > 0) {
      sections[currentIndex - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (direction === 'next' && currentIndex < sections.length - 1) {
      sections[currentIndex + 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};

// Function to set up an IntersectionObserver
export const setupIntersectionObserver = (setActiveSection) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const sections = document.querySelectorAll('section');
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return observer;
  }

  return { disconnect: () => {} }; // Return a no-op observer in SSR
};